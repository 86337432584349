const USER_ROLE_SUNSOUL = 'Restricted Merchant';
const USER_ROLE_ADMIN = 'Admin';
const USER_ROLE_LICENSEE = 'Group 5';

export const canSeeCustomerDetails = userRole => {
    return userRole !== USER_ROLE_SUNSOUL;
};

export const canSearchCustomerDetails = userRole => {
    return userRole !== USER_ROLE_SUNSOUL && userRole !== USER_ROLE_LICENSEE;
};

export const alwaysSendNotificationEmail = userRole => {
    return userRole === USER_ROLE_SUNSOUL;
};

export const shouldCreateZohoTaskForOrder = userRole => {
    return userRole === USER_ROLE_SUNSOUL;
};

export const canMarkDelivered = userRole => {
    return userRole === USER_ROLE_ADMIN;
};

export const shouldSeeDealerFields = userRole => {
    return userRole === USER_ROLE_LICENSEE || userRole === USER_ROLE_SUNSOUL;
};

export const createOrdersUnderUserAccount = userRole => {
    return userRole === USER_ROLE_LICENSEE;
};

export const onlyShowSunSoulProducts = userRole => {
    return userRole === USER_ROLE_SUNSOUL;
};

export const hideSunSoulProducts = userRole => {
    return userRole !== USER_ROLE_ADMIN && userRole !== USER_ROLE_SUNSOUL;
};