import 'bootstrap/dist/css/bootstrap.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
import 'datatables.net-select-bs4/css/select.bootstrap4.css';
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css';

import 'datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.css';

import './style.scss';

import $, { data } from 'jquery';

// require datatables without invoking or passing arguments to make it work with webpack
import 'bootstrap';
import 'datatables.net';
import 'datatables.net-bs4';

// Buttons
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';

// Header
import 'datatables.net-fixedheader';

// Search Panes
import 'datatables.net-select';
import 'datatables.net-searchpanes';
//require('datatables.net-searchpanes-bs4');
// Patched for https://github.com/DataTables/SearchPanes/issues/16
import './searchpanes.bootstrap4';
import { canMarkDelivered, canSeeCustomerDetails } from '../private/permissions';

const expectedProducts = __NUM_PRODUCTS__ || 15000;
const pagesize = 1500;

let user = {};
const reload_table = () => {
  if (window.table) {
    window.table.$('tr').css('opacity', 0.3);
    window.table.ajax.reload();
  }
}

const state = {
  include_virtual: 0,
  setState: function (key, value) {
    if (this[key] === value) {
      return;
    }

    this[key] = value;

    // Update DOM
    if (this.include_virtual) {
      $('.include-virtual').addClass('active');

    } else {
      $('.include-virtual').removeClass('active');

    }

    reload_table();

  }
};

const isSunAndSoul = () => {
  if (new URLSearchParams(document.location.search).get("sunsoul") === "1") {
    return true;
  }

  return user.UserRole === 'Restricted Merchant';
};

const currentUserCanMarkDelivered = () => {
  return canMarkDelivered(user.UserRole);
};

const currentUserCanSeeCustomerDetails = () => {
  return canSeeCustomerDetails(user.UserRole);
};

const render_bool = function (data, type) {
  if (type === 'display') {
    return data ? 'Yes' : 'No';
  }
  return data;
};

const ucFirst = str => str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : str;

const buttons = {
  createQuote: '<a href="#" class="btn btn-light btn-loading-overlay create-quote"><span class="spinner"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></span><span class="btn-inner-text">Create Quote</span></a>',
  createOrder: '\
    <div class="btn-group" role="group">\
      <a href="#" class="btn btn-secondary btn-loading-overlay create-order" style="color:white">\
      <span class="spinner"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></span><span class="btn-inner-text">Create Order</span>\
      </a>\
      <a href="#" class="btn btn-secondary select-btn" style="color:white" onclick="event.preventDefault()">\
      <span class="select-checkbox"></span>\
      </a>\
    </div>',
  markDelivered: '<a href="#" class="btn btn-info btn-loading-overlay mark-delivered" style="color:white"><span class="spinner"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></span><span class="btn-inner-text">Mark Delivered</span></a>'
};

const render_date = (data, type) => {
  if ('0000-00-00' === data) {
    return '';
  }
  if (type !== 'display') {
    return data;
  }

  if ('2999-12-31' === data) {
    return 'TBC';
  }

  try {
    const date = data.split('-');
    if (date.length === 3) {
      date.reverse();
      return date.join('-');
    }

  } catch (e) { }

  return '';
};

const render_default = (def) => data => data || def;

// https://gist.github.com/mathewbyrne/1280286
function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

// Set vars on the window
const logo = __LOGO__ ? __LOGO__ : 'logo.png';
$('.logo').attr('src', 'assets/' + logo);

$('.open-cpanel').attr('href', __SITE__ + '_cpanel/');
$('title, .title').text(`${__SITENAME__} Stock Manager`);

// Check if we have a login session
$.get('/api/login').then(res => {
  if (res.redirect) {
    window.location.href = res.redirect;
    return;
  }

  user = res.user;

  $('body').css('opacity', 1);

  // Draw the table
  startStockManager();

  // Enable the Keap features if allowed
  if (currentUserCanSeeCustomerDetails()) {
    typeof (window.CustomerForm) !== 'undefined' && $('.customer-form').removeClass('d-none');
    $('.d-admin').removeClass('d-admin');

    // Just in case some elements with d-admin are still added via AJAX
    $('head').append('<style id="show-admin" type="text/css">.d-admin {display: block !important;}</style>')
  }

});

const isAvailable = (row) => parseInt(row['AvailableSellQuantity']) > 0 || row['Virtual'] === 'True';

const doNotSell = [
  'Sold',
  'Damaged',
  'Damaged IT',
  'Finance Hold',
  'Floor',
  'Floor IT',
  'QA Hold',
  'QA Hold IT',
  'Second',
  'Second IT',
  'Show Stock',
  'Show Stock IT',
  'Used',
  'Used IT',
  'Delivered'
];

const isSellable = (row) => doNotSell.indexOf(row['Misc07']) === -1 && isAvailable(row);

const render_button_col = (data, type, row) => {

  if (type === 'export') {
    return data ? [data, row['Misc12']].join(' - ') : '';
  }

  if (type === 'filter') {
    // Include all the metadata
    return [row['Misc12'], data].join(' ');
  }

  const outButtons = [];
  const skuIsAvailable = isAvailable(row);

  // If is deliverable, add delivery button
  const is_deliverable = !skuIsAvailable && row['Misc07'] !== 'Delivered';

  if (is_deliverable && currentUserCanMarkDelivered()) {
    outButtons.push(buttons.markDelivered);
  }

  // Only show order button if product isn't actually in stock
  if (data && !skuIsAvailable) {
    outButtons.push(`<a href="${__SITE__}_cpanel/order/vieworder?id=${data}" target="_blank" class="btn btn-success"><span class="btn-inner-text">${data}</span></a>`);
  }

  // And if the product is sellable
  if (!isSellable(row)) {
    return outButtons.join('');
  }

  outButtons.push(buttons.createOrder);

  return outButtons.join('');
}

const columns = [
  {
    "className": 'details-control d-admin',
    "orderable": false,
    "data": null,
    "defaultContent": ''
  },
  { title: "Serial Number", data: 'SKU' },
  { title: "SKU", data: 'AccountingCode' },
  { title: "Model", data: 'Misc14' },
  { title: "Name", data: 'Name' },
  { title: "Shell Colour", data: 'Shell Colour' },
  { title: "Cabinet Colour", data: 'Cabinet Colour' },
  { title: __KEAP_ENV__ === 'SWNZ' ? "Suburb" : "City", data: 'City', visible: false },
  { title: __KEAP_ENV__ === 'SWNZ' ? "City" : "State", data: 'State', visible: false },
  { title: "Postcode", data: 'Postcode', visible: false },
  { title: "Condition", data: 'Misc07', className: 'condition' },
  {
    title: "Status", data: 'AvailableSellQuantity', render: (data, type, row) => {
      return isAvailable(row) ? 'Available' : 'Sold';
    }
  },
  { title: "Location", data: 'Location' }, // default warehouse
  {
    title: "Stock on Hand", data: 'AvailableSellQuantity', render: (data, type, row) => {
      if (type !== 'display') {
        return data;
      }
      return parseInt(data) > 0 ? data : 0;
    }, searchPanes: { show: false }
  },
  { title: "Date Available", data: 'DateArrival', render: render_date },
  {
    title: "SOH Age", data: 'StockAge', render: (data, type, row) => {
      if (type !== 'display') {
        return data;
      }

      if (row['Misc07'] === 'Floor' || row['Misc07'] === 'Floor IT') {
        return data;
      }

      const age = parseInt(data);
      if (!age) {
        return data;
      }

      const badges = [
        'success',
        'light',
        'warning',
        'danger'
      ];

      let badge = age < 60 ? badges[0] : age < 120 ? badges[1] : age < 180 ? badges[2] : badges[3];
      return `<span class="badge badge-${badge}">${data}</span>`;

    }, searchPanes: { show: false, data: "Stockage" }
  },
  //{ title: "Estimated Delivery", data: 'Misc29', render: render_date },
  {
    title: "Container", className: 'nowrap', data: 'Misc13', render: (data, type, row) => {
      if (type !== 'display') {
        return data;
      }
      if (!data || !__ENABLE_SHIPPING_INFO__) {
        return data;
      }

      return data + '<a class="btn btn-light mt-1 btn-block btn-sm show-shipping" data-container="' + data + '" href="#">View</a>';
    }, searchPanes: { show: false }
  },
  //{ title: "Date Ordered", data: 'Misc28', render: render_date },
  { title: "Is On Special", data: 'Specials', render: render_bool },
  { title: "Is On Clearance", data: 'Clearance', render: render_bool },
  {
    title: "",
    data: "Misc11",
    orderable: false,
    searchPanes: {
      show: false,
    },
    className: 'button-col',
    render: render_button_col
  }
].filter(_ => !_['title'] || !(__EXCLUDE_COLS__ || '').split(',').includes(_['title']));

const startStockManager = async function () {

  const table = window.table = $('#datatable').DataTable({
    //scrollX: true,
    select: {
      style: 'multi',
      selector: '.select-btn'
    },
    deferRender: true,
    fixedHeader: true,
    pageLength: 25,
    searchPanes: {
      emptyMessage: "</i></b>EMPTY</b></i>",
      layout: 'columns-6',
      cascadePanes: true
    },
    processing: true,
    language: {
      processing: 'Loading stock...<div id="dt-loading" class="progress"><div class="progress-bar progress-bar-animated progress-bar-striped bg-success" role="progressbar" style="width: 0;"></div></div>'
    },
    dom:
      "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 text-right col-md-6'B>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row mb-2'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
    buttons: [
      {
        extend: 'csv',
        text: 'Export CSV',
        exportOptions: { orthogonal: 'export' }
      },
      'colvis',
      {
        text: '<span class="spinner"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></span><span class="btn-inner-text">Create Order</span>',
        className: 'show-if-selection global-create-order btn-success btn-loading-overlay',
        action: function (e, dt, node, config) {
          createOrderSelected({});
        }
      },
      {
        text: 'Clear Selection',
        className: 'show-if-selection btn-danger',
        action: function (e, dt, node, config) {
          table.rows().deselect();
        }
      },
    ],
    ajax: (data, callback, settings) => {
      (async () => {

        const lastUpdated = await $.get('api/products-last-updated');

        console.log('Got last updated:', lastUpdated);

        // Make parallel requests to speed things up
        // We're expecting ~6,000 products. Split into 6x 1,000 product requests
        // Also: Vercel has a limit 5MB payload
        const maxPages = expectedProducts / pagesize;

        const allProductPages = [];
        for (let page = 0; page < maxPages; page++) {
          allProductPages.push($.get(`api/products?page=${page}&per_page=${pagesize}&include_virtual=${state.include_virtual}&role=${user.UserRole}&ts=${lastUpdated}`));
        }

        let doneCount = 0;
        const overallCount = allProductPages.length;
        const $progress = $('#dt-loading .progress-bar');

        $progress.css('width', '0');
        $progress.text('0%');

        const handleProgress = (result) => {
          doneCount++;
          const percentageDone = parseInt(doneCount / overallCount * 100);

          $progress.css('width', percentageDone + '%');
          $progress.text(percentageDone + '%');
          return result;

        };

        try {
          const allProducts = await Promise.all(allProductPages.map(p => p.then(handleProgress)));

          // Check if we are logged in
          if (allProducts[0].redirect) {
            window.location.href = allProducts[0].redirect;
            return;
          }

          const products = allProducts.flat();

          callback({ data: products });

          return true;

        } catch (e) {
          alert('Could not connect to Neto. Please reload the page and try again later.');
          return;
        }

      })()
    },
    columns,
    "order": [[1, 'asc']],

    // Add row classes
    createdRow: function (row, data, index) {
      const cats = data['Categories'] || [];
      const classes = cats.map(_ => _['CategoryName']);

      classes.push(isAvailable(data) ? 'Available' : 'Sold');
      classes.push(data['Virtual'] === 'True' ? 'Virtual' : 'Serialized');

      data['Misc07'] && classes.push(data['Misc07']);

      row.classList = [...row.classList, ...classes.map(slugify)].join(' ');

    }
  });

  table.searchPanes.container().appendTo($('#searchpanes'));

  // Scroll to Top
  table.on('page', function () {
    setTimeout(function () {
      //$(document).scrollTop($(table.table().container()).offset().top);
      $("html, body").animate({ scrollTop: $(table.table().container()).offset().top }, 200);
    }, 10);
  });

  //
  // CHILD ROWS
  //

  const render_child_table = (columns, childRowData) => {
    const spinner = '<div class="placeholder-loading" role="status"><div></div></div>';

    let ret = '<table style="padding-left:50px;" class="table table-striped table-bordered table-child-row">';

    columns.forEach((col, i) => {
      /// Skip rendering if orderdata is not available yet.
      if (!childRowData) {
        ret += '<tr><th style="white-space: pre">' + col.title + '</th><td width="100%">' + spinner + '</td></tr>';
        return;
      }

      let data = col.data && childRowData && childRowData[col.data] ? childRowData[col.data] : '';

      if (col.renderer) {
        data = col.renderer(data);
      }

      ret += '<tr><th style="white-space: pre">' + col.title + '</th><td width="100%">' + data + '</td></tr>';
    })
    ret += '</table>';

    return ret;
  }

  function format_product_subrow(row) {
    //const spinner = '<div class="spinner-border text-primary spinner-border" role="status"></div>';

    const _render_table = (orderData) => {
      const columns = currentUserCanSeeCustomerDetails() ? [
        { title: "Internal Product Notes", data: 'AvailabilityDescription' },
        { title: "SO#", data: 'OrderID', renderer: _ => _ ? `<a href="${__SITE__}_cpanel/order/vieworder?id=${_}" target="_blank">${_}</a>` : _ },
        { title: "Order Status", data: 'OrderStatus' },
        { title: "Customer Name", data: 'CustomerName' },
        { title: "Internal Order Notes", data: 'InternalOrderNotes' },
      ] : [
        { title: "SO#", data: 'OrderID' },
        { title: "Order Status", data: 'OrderStatus' }
      ];

      return render_child_table(columns, orderData);
    }

    var div = $('<div/>');

    div.append(_render_table());

    $.get('/api/product?sku=' + row.SKU).then(json => {
      div.html(_render_table(json));
    });

    return div;

  }

  function format_shipping_subrow(row) {

    const _render_table = (data) => {
      const columns = [
        { title: 'Shipment ID', data: 'SHIPMENT ID' },
        { title: 'Customer PO / Container ID', data: 'CUSTOMER PO / CONTAINER ID' },
        { title: 'Container Num', data: 'CONTAINER NUM' },
        { title: 'Supplier', data: 'SUPPLIER' },
        //{ title: 'Ready Date', data: 'READY DATE' },
        { title: 'Process Type', data: 'PROCESS TYPE' },
        { title: 'Origin', data: 'ORIGIN' },
        { title: 'Destination', data: 'DESTINATION' },
        { title: 'Pre-carrying Vessel/Aircraft', data: 'PRE-CARRYING VESSEL/AIRCRAFT' },
        { title: 'Mother Vessel/Aircraft', data: 'MOTHER VESSEL/AIRCRAFT' },
        { title: 'ETD', data: 'ETD' },
        { title: 'PORT ETA', data: 'PORT ETA' },
        { title: 'Container Size', data: 'CONTAINER SIZE' },
        { title: 'Status', data: 'STATUS' },
        { title: 'LAST UPDATE', data: 'LAST UPDATE' },
        //{ title: 'Detention Date', data: 'Detention Date' },
        //{ title: 'CHECKED ONLINE STOCK DATE', data: 'CHECKED ONLINE STOCK DATE' },
        { title: 'Notes', data: __KEAP_ENV__ === 'SWNZ' ? 'FIELD17' : 'FIELD18' },
      ];

      return render_child_table(columns, data);
    }

    var div = $('<div/>');

    div.append(_render_table());

    $.get('/api/get-shipping-info?container=' + row.Misc13).then(json => {
      div.html(_render_table(json['shipment'] ? json['shipment'] : { 'Shipment ID': 'No shipment information available.' }));
    });

    return div;

  }

  // Add event listener for opening and closing details
  const toggleChildRow = (el, type, cb) => {
    var tr = $(el).closest('tr');
    var row = table.row(tr);

    if (tr.hasClass('shown-' + type)) {
      row.child.hide();
      tr.removeClass('shown-' + type);
    } else {
      row.child(cb(row.data())).show();

      // Remove other "shown" classes
      const shownClasses = tr.attr('class').match(/\bshown-\w*/);
      if (shownClasses) {
        shownClasses.forEach(cls => tr.removeClass(cls));

      }

      tr.addClass('shown-' + type);
    }

  }

  $('#datatable tbody').on('click', '.serialized td.details-control', function () {
    toggleChildRow(this, 'details', format_product_subrow);
  });

  // Add event listener for opening and closing shipping
  $('#datatable tbody').on('click', '.show-shipping', function (e) {
    e.preventDefault();
    toggleChildRow(this, 'shipping', format_shipping_subrow);
  });

  //
  // SEARCH
  //
  $("#table-search").on('keyup change', function () {
    table.search($(this).val(), true).draw();
  });

  //
  // Toggle virtual products
  //
  $('.include-virtual').on('click', function (e) {
    e.preventDefault();

    // Toggle the state
    state.setState('include_virtual', state.include_virtual ? 0 : 1);
  });

  //
  // Reload button
  //
  $('.reload-table').on('click', function (e) {
    e.preventDefault();
    reload_table();
  });

  //
  // CREATE ORDER
  //
  function createOrderSelected({ type, cancelCallback }) {
    const selected = table.rows({ selected: true }).data().toArray().map(row => ({
      sku: row['SKU'],
      warehouseCode: row['WarehouseReference'],
      enableQty: row['Virtual'] === 'True',
      name: row['Name']
    }));

    $('.global-create-order').addClass('btn-loading').attr("disabled", true);
    const not_loading = () => { $('.global-create-order').removeClass('btn-loading').attr("disabled", false); }

    createOrder(type ? type : 'order', selected, () => {
      // Cancel
      not_loading();

      cancelCallback && cancelCallback();

    }, (response) => {
      not_loading();

      // Complete
      table.rows({ selected: true }).nodes().each(tr => {
        const orderUrl = __SITE__ + '_cpanel/order/vieworder?id=' + response.orderId;

        $(tr).addClass('on-hold');

        const $btn = $('.create-order', tr)

        $btn
          .removeClass('btn-loading btn-secondary create-order create-quote btn-light')
          .addClass('btn-success')
          .text(response.orderId)
          .attr('href', currentUserCanSeeCustomerDetails() ? orderUrl : '#')
          .attr('target', '_blank');

        // Remove other buttons in same cell
        $btn.parent().find('.btn:not(.btn-success)').remove();
      });

      table.rows().deselect();

    });
  }

  function createOrder(type, skus, cancelCallback, completeCallback) {

    // Create order!
    window.OrderForm.open({
      type: type,
      products: skus,
      user: user,
      successCallback: ({
        email,
        dateRequired,
        salesType,
        dealerCustomerName,
        dealerPO,
        shippingAddress
      }, order) => {

        $.ajax('/api/order', {
          data: JSON.stringify({
            type,
            email,
            dateRequired,
            salesType,
            order,
            dealerCustomerName,
            dealerPO,
            shippingAddress
          }),
          contentType: 'application/json',
          type: 'POST',
        })
          .then(function (response) {
            if (response.status === 'success') {
              // Clean up the UI
              completeCallback(response);

              // Open the order if we're allowed to.
              const orderUrl = __SITE__ + '_cpanel/order/vieworder?id=' + response.orderId;
              currentUserCanSeeCustomerDetails() && window.open(orderUrl);

            } else {
              alert(response.error);
              cancelCallback();
            }
          })
          .fail(function () {
            cancelCallback();
          });

      },
      cancelCallback,
    });

  }

  table.on('select deselect', function (e, dt, type, indexes) {
    if (table.rows({ selected: true }).data().length > 0) {
      $('#datatable_wrapper').addClass('has-selection');
    } else {
      $('#datatable_wrapper').removeClass('has-selection');
    }

  });

  $('#datatable').on('click', '.create-order, .create-quote', function (e) {
    e.preventDefault();

    const $this = $(this);
    const type = $(this).hasClass('create-order') ? 'order' : 'quote';
    const tr = $this.closest('tr');
    table.row(tr).select();

    $this.addClass('btn-loading').attr("disabled", true);

    createOrderSelected({
      type,
      cancelCallback: () => {
        $this.removeClass('btn-loading').attr("disabled", false);
      }
    });


  });

  // Mark Delivered
  $('#datatable').on('click', '.mark-delivered', function (e) {
    e.preventDefault();

    const $this = $(this);
    const tr = $this.closest('tr');
    const row = table.row(tr).data();
    const sku = row['SKU'];

    if (!confirm(`This will mark item ${sku} as delivered and remove it from the stock list.`)) {
      return;
    }

    $this.addClass('btn-loading').attr("disabled", true);
    const cancel = _ => $this.removeClass('btn-loading').attr("disabled", false);

    $.post('/api/mark-delivered', {
      sku
    })
      .then(function (response) {
        if (response.status === 'success') {

          tr.addClass('delivered');

          $this.parent().html('<span class="badge badge-success">Marked as Delivered</span>');
          tr.find('td.condition').text('Delivered')

        } else {
          alert(response.error);
          cancel();
        }
      })
      .fail(function () {
        cancel();
      });

  });

};
