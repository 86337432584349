import React from 'react';
import ReactDOM from 'react-dom';
import CustomerFormNeto from './CustomerFormNeto.jsx';
import CustomerFormZoho from './CustomerFormZoho.jsx';
import 'bootstrap/dist/css/bootstrap.css';
import OrderForm from './OrderForm.jsx';

if ( document.getElementById('dev') ) {
    ReactDOM.render(<>
        <OrderForm getMethods={methods => window.OrderForm = methods} />
    </>, document.getElementById('dev'));

        setTimeout(_ => {
            window.OrderForm.open({
                successCallback: _ => _,
                cancelCallback: _=> _,
                type: 'order',
                products: [{
                    sku: 'BLA',
                    warehouseCode: '123',
                    enableQty: false,
                    name: 'TEST'
                }],
                user: {
                    UserRole: 'Group 5',
                    Territory: '300W'
                }
          });

        }, 0)
}