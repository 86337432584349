'use strict';

import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { ajax, get } from 'jquery';
import {default as setDescendantProp} from 'lodash.set';

import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';

// https://community.keap.com/t/invalid-country-code-and-region-what-is-valid/13354/10
const states = {
    'AB': 'Alberta',
    'BC': 'British Columbia',
    'MB': 'Manitoba',
    'NB': 'New Brunswick',
    'NL': 'Newfoundland and Labrador',
    'NS': 'Nova Scotis',
    'ON': 'Ontario',
    'PE': 'Prince Edward Island',
    'QC': 'Quebec',
    'SK': 'Saskatchewan',
    'NT': 'Northwest',
    'NU': 'Nunavut',
    'YT': 'Yukon'
};

// Custom JSON POST function for jQuery. jQuery turns body into URL params automatically.
const post = function(url, body) {
    return ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(body),
        contentType: "application/json",
        dataType: 'json'
    });
}

const phone_types = [
    'Work', 'Home', 'Mobile', 'Other'
];

const fields = {
    'opt_in': {
        type: 'checkbox',
        label: 'I have permission to send marketing to this address',
        value: 'Contact gave explicit permission.',
        neto: 'NewsletterSubscriber'
    },
    'contact-first-name': {
        type: 'text',
        label: 'Contact First Name *',
        required: true,
        neto: 'BillingAddress.BillFirstName'
    },
    'contact-last-name': {
        type: 'text',
        label: 'Contact Last Name',
        neto: 'BillingAddress.BillLastName'
    },
    'company': {
        type: 'text',
        label: 'Company Name',
        neto: 'BillingAddress.BillCompany'
    },
    'phone-1': {
        type: 'text',
        label: 'Phone 1',
        neto: 'BillingAddress.BillPhone'
    },
    'delivery-street-address-1': {
        type: 'text',
        label: 'Delivery Street Address 1',
        neto: 'BillingAddress.BillStreetLine1'
    },
    'delivery-street-address-2': {
        type: 'text',
        label: 'Delivery Street Address 2',
        neto: 'BillingAddress.BillStreetLine2'
    },
    'delivery-city': {
        type: 'text',
        label: 'Delivery City',
        neto: 'BillingAddress.BillCity'
    },
    'delivery-state': {
        type: 'select',
        label: 'Delivery Province',
        neto: 'BillingAddress.BillState',
        options: 'states'
    },
    'delivery-postal-code': {
        type: 'text',
        label: 'Delivery Postal Code',
        neto: 'BillingAddress.BillPostCode'
    },
    'territory': {
        type: 'select',
        label: 'Territory', // TODO
        neto: 'UserCustom2',
        options: 'salesTypes'
    }
};

const upsertCustomer = async (data) => {
    console.log('Upserting cusomer', data);

    // Upsert in Neto (field mapping?)
    const neto = {
        'EmailAddress': data['customer_email'],
        'BillCountry': 'CA'
    }

    Object.entries(fields).filter(([key, field]) => data[key]).map(([key, field]) => {
        if ( !field.neto ) {
            return;
        }

        let value = data[key];
        if (field.type === 'checkbox') {
            value = !!value;
        }

        setDescendantProp(neto, field.neto, value);
    });

    const response = await post('/api/keap/upsert-contact', JSON.stringify({neto}), );

    if ( !response.success ) {
        alert('Something went wrong saving this contact in Neto. Please try again later.')
    }

};

export default function CustomerFormNeto({ cb }) {
    const [loading, setLoading] = useState();
    const [submitting, setSubmitting] = useState();
    const [salesTypes, setSalesTypes] = useState();

    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur'
    });

    useEffect(() => {
        get_sales_types();

        async function get_sales_types() {
          const sales_types = await get('/api/salestypes');

          setSalesTypes(Array.from(sales_types).filter(_ => _['Code'] && _['Code'].substr(-1) === 'R').map(_ => ({
            id: _['Code'].trim(),
            label: [_['Code'].trim(), (_['Description']||'').trim()].join(' - ')
          })));
        }
    }, []);

    const labelWidth = 6;
    const inputWidth = 12 - labelWidth;

    const onSubmit = async data => {
        setSubmitting(true);

        await upsertCustomer(data);
        setSubmitting(false);
        cb();
    };

    const getOptions = (key, field) => {

        if (field.options === 'states') {
            return Object.entries(states).map(([key, state]) => ({
                id: key,
                label: state
            }));
        }

        if (field.options === 'salesTypes') {
            return salesTypes||[];
        }

    };

    return !submitting ? <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
            <Form.Label column sm={labelWidth}>Customer Email:</Form.Label>
            <Col sm={inputWidth}>
                <Form.Control
                    type="email"
                    name={"customer_email"}
                    placeholder="example@customer.com"
                    ref={register({
                        required: "Please enter the email address"
                    })}
                    isInvalid={errors["customer_email"]}
                />
            </Col>
        </Form.Group>

        {Object.entries(fields).map(([key, field]) => (
            <Form.Group as={Row} key={'field_' + key}>
                <Form.Label column sm={labelWidth}>{field.type !== 'checkbox' && field.label}</Form.Label>
                <Col sm={inputWidth}>

                    {(field.type === 'select' &&
                        <Form.Control
                            as="select"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            >
                            <option value=""></option>
                            {getOptions(key, field).map(option =>
                                    <option value={option.id} key={`f-${key}-${option.id}`}>{option.label}</option>
                                )
                            }
                        </Form.Control>)
                    ||
                    (field.type === 'checkbox' &&
                        <Form.Check
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            value={field.value}
                            label={field.label}
                            />)
                    ||
                    (field.type === 'phone_type' &&
                        <Form.Control
                            as="select"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            >
                            <option value=""></option>
                            {phone_types.map(_ => <option value={_} key={`ph-${key}-${_}`}>{_}</option>)}
                        </Form.Control>
                    )
                    ||
                    (field.type === 'textarea' &&
                        <Form.Control
                            as="textarea"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            row={3}
                            isInvalid={errors[key]}
                            disabled={loading}
                            />
                    )
                    ||
                    <Form.Control
                        type={field.type}
                        name={key}
                        placeholder=""
                        ref={register({
                            required: field.required
                        })}
                        isInvalid={errors[key]}
                        disabled={loading}
                        />
                    }
                </Col>
            </Form.Group>
        ))}


        <Button variant="primary" type="submit">
            {"Create Contact"}
        </Button>

    </Form> : <div>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner> {submitting && "Saving contact..."}
    </div>
}
