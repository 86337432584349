'use strict';

import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { ajax, get } from 'jquery';
import { default as setDescendantProp } from 'lodash.set';
import { default as getDescendantProp } from 'lodash.get';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useForm } from 'react-hook-form';

// Custom JSON POST function for jQuery. jQuery turns body into URL params automatically.
const post = function (url, body) {
    return ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(body),
        contentType: "application/json",
        dataType: 'json'
    });
}

const fields = {
    'contact-title': {
        type: 'text',
        label: 'Title',
        required: false,
        zoho: 'Title'
    },
    'contact-salutation': {
        type: 'select',
        label: 'Contact Salutation',
        required: false,
        options: 'contactModel',
        zoho: 'Salutation'
    },
    'contact-first-name': {
        type: 'text',
        label: 'Contact First Name',
        required: true,
        neto: 'BillingAddress.BillFirstName',
        zoho: 'First_Name'
    },
    'contact-last-name': {
        type: 'text',
        label: 'Contact Last Name',
        required: true,
        neto: 'BillingAddress.BillLastName',
        zoho: 'Last_Name'
    },
    'contact-spouse-name': {
        type: 'text',
        label: 'Spouse Name',
        required: false,
        zoho: 'Spouse'
    },
    'contact-secondary-email': {
        type: 'email',
        label: 'Secondary Email',
        required: false,
        neto: 'SecondaryEmailAddress',
        zoho: 'Secondary_Email',
    },
    'contact-mobile': {
        type: 'text',
        label: 'Mobile',
        required: false,
        neto: 'BillingAddress.BillPhone',
        zoho: 'Mobile',
    },
    'contact-phone': {
        type: 'text',
        label: 'Phone',
        required: false,
        neto: 'ShippingAddress.ShipPhone',
        zoho: 'Phone',
    },
    'contact-extension': {
        type: 'text',
        label: 'Extension',
        required: false,
        zoho: 'Extension',
    },
    'contact-additional-phone': {
        type: 'text',
        label: 'Additional Phone',
        required: false,
        zoho: 'Additional_Phone',
    },
    'contact-additional-extension': {
        type: 'text',
        label: 'Additional Phone Extension',
        required: false,
        zoho: 'Additional_Phone_Extension',
    },
    // TODO: Congiere field doesn't update. Not sure if it's required!
    //'concierge': {
    //    type: 'select',
    //    label: 'Concierge',
    //    required: false,
    //    options: 'users',
    //    zoho: 'Concierge',
    //},
    'lead-owner': {
        type: 'select',
        label: 'Lead Owner',
        required: true,
        options: 'users',
        zoho: 'Owner.id',
        sorted: true
    },
    'opt_out': {
        type: 'checkbox',
        label: 'Opt-Out of Email Marketing',
        value: true,
        neto: 'NewsletterSubscriber',
        zoho: 'Email_Opt_Out'
        // TODO: Opting back in is not possible right now. The "false" value is not submitted to Zoho
    },
    'delivery-street-address-1': {
        type: 'text',
        label: 'Delivery Street',
        neto: 'ShippingAddress.ShipStreetLine1',
        zoho: 'Single_Line_15'
    },
    'delivery-street-address-2': {
        type: 'text',
        label: 'Delivery Unit/Apartment Number',
        neto: 'ShippingAddress.ShipStreetLine2',
        zoho: 'Single_Line_14'
    },
    'delivery-city': {
        type: 'text',
        label: 'Delivery City',
        neto: 'ShippingAddress.ShipCity',
        zoho: 'Delivery_City'
    },
    'delivery-state': {
        type: 'select',
        label: 'Delivery Province',
        neto: 'ShippingAddress.ShipState',
        options: 'contactModel',
        zoho: 'Delivery_State',
        sorted: true
    },
    'delivery-postal-code': {
        type: 'text',
        label: 'Delivery Postal Code',
        neto: 'ShippingAddress.ShipPostCode',
        zoho: 'Delivery_Postcode',
        required: true
    },

    'billing-street-address-1': {
        type: 'text',
        label: 'Billing Street',
        neto: 'BillingAddress.BillStreetLine1',
        zoho: 'Billing_Address_Street'
    },
    'billing-street-address-2': {
        type: 'text',
        label: 'Billing Unit/Apartment Number',
        neto: 'BillingAddress.BillStreetLine2',
        zoho: 'Billing_Street_2'
    },
    'billing-city': {
        type: 'text',
        label: 'Billing City',
        neto: 'BillingAddress.BillCity',
        zoho: 'Billing_Address_City'
    },
    'billing-state': {
        type: 'select',
        label: 'Billing Province',
        neto: 'BillingAddress.BillState',
        options: 'contactModel',
        zoho: 'Billing_State_All',
        sorted: true
    },
    'billing-postal-code': {
        type: 'text',
        label: 'Billing Postal Code',
        neto: 'BillingAddress.BillPostCode',
        zoho: 'Billing_Postcode'
    },
    //'brand-interest': {
    //    type: 'text',
    //    label: 'Brand Interest',
    //    zoho: 'Interest'
    //},
    'product-interest': {
        type: 'select',
        label: 'Product Interest',
        zoho: 'Product_Interest',
        options: 'contactModel'
    },
    'sales-lead-from': {
        type: 'select',
        label: 'Sales Lead From',
        zoho: 'Sales_Lead_From',
        options: 'contactModel'
    }
};

let contactRequest = false;
const getZohoContact = async email => {
    if (!email) {
        return false;
    }

    if (contactRequest) {
        contactRequest.abort();
    }

    try {
        contactRequest = get('/api/zoho/contacts?email=' + email);
        const existing = await contactRequest;
        return existing.data[0];

    } catch { }

}

const upsertCustomer = async (data) => {
    console.log('Upserting cusomer', data);

    // Upsert in Zoho
    const customer = {
        Email: data['customer_email'],
        'Delivery_Country': __COUNTRY__ === 'NZ' ? 'New Zealand' : 'Australia',
        'Billing_Country': __COUNTRY__ === 'NZ' ? 'New Zealand' : 'Australia',
    };

    Object.entries(fields).filter(([key, field]) => data[key]).map(([key, field]) => {
        if (!field.zoho) {
            return;
        }

        let value = data[key];
        if (field.type === 'checkbox') {
            value = !!value;
        }

        setDescendantProp(customer, field.zoho, value);

    });


    // Upsert in Neto
    const neto = {
        'EmailAddress': data['customer_email'],
        'BillCountry': __COUNTRY__
    }

    Object.entries(fields).filter(([key, field]) => data[key]).map(([key, field]) => {
        if (!field.neto) {
            return;
        }

        let value = data[key];
        if (field.type === 'checkbox') {
            value = !!value;
        }

        if (key === 'opt_out') {
            value = !value; // Invert value
        }

        setDescendantProp(neto, field.neto, value);
    });

    const response = await post('/api/zoho/upsert-contact', JSON.stringify({ customer, neto }),);

    if (!response.success) {
        alert('Something went wrong saving this contact in Zoho and Maropost. Please try again later.')
    }

};

export default function CustomerFormZoho({ cb, defaultEmail }) {
    const [zohoContact, setZohoContact] = useState();
    const [loading, setLoading] = useState();
    const [submitting, setSubmitting] = useState();
    const [contactModel, setContactModel] = useState();
    const [users, setUsers] = useState();
    const [zohoEmail, setZohoEmail] = useState();

    const { register, handleSubmit, watch, errors, control, setValue } = useForm({
        mode: 'onBlur'
    });

    useEffect(() => {
        get_contact_model();
        get_users();

        async function get_contact_model() {
            const contact_model = await get('/api/zoho/contact-model');
            setContactModel(contact_model);
        }
        async function get_users() {
            const users = await get('/api/zoho/users');
            setUsers(users);
        }
    }, []);

    useEffect(() => {

        if (users && contactModel && defaultEmail && !zohoEmail) {
            on_update_email(defaultEmail);
        }
    }, [users, contactModel]);

    const on_update_email = async (email) => {

        // Unchanged?
        if (email === zohoEmail) {
            return true;
        }

        setZohoContact(false);

        setZohoEmail(email);
        setLoading(true);

        const contact = await getZohoContact(email);

        setZohoContact(contact);
        setLoading(false);

        if (!contact) {
            return;
        }

        // Fill in form fields
        Object.entries(fields).map(([key, field]) => {
            if (field.prefill === false) {
                setValue(key, '');
            } else {
                setValue(key, getDescendantProp(contact, field.zoho));
            }
        });

    }

    const labelWidth = 6;
    const inputWidth = 12 - labelWidth;

    const onSubmit = async data => {
        setSubmitting(true);

        await upsertCustomer(data);
        setSubmitting(false);
        cb();
    };

    const getOptions = (key, field) => {

        const maybeSort = list => field.sorted ? list.sort((a, b) => a.label.localeCompare(b.label)) : list;

        if (field.options === 'users') {

            return maybeSort(users.map(_ => ({
                id: _.id,
                label: _.full_name
            }))) || [];
        }

        if (field.options === 'contactModel') {
            try {

                const zohoField = contactModel.fields.filter(_ => _['api_name'] === field.zoho)[0];

                let options = maybeSort(zohoField.pick_list_values.map(_ => ({
                    id: _.actual_value,
                    label: _.display_value
                })));

                if (key.indexOf('state') !== -1) {
                    const testIsLower = /[a-z]/;
                    options = options.filter(option => {
                        const isLower = testIsLower.test(option.label);
                        return __COUNTRY__ === 'NZ' ? !!isLower : !isLower;
                    });
                }

                return options;

            } catch {
                return [];
            }

        }

        if (Array.isArray(field.options)) {
            return maybeSort(field.options.map(_ => ({
                id: _,
                label: _
            })));
        }

        return [];
    };

    return !submitting && contactModel && users ? <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
            <Form.Label column sm={labelWidth}>Customer Email *</Form.Label>
            <Col sm={inputWidth}>
                <Form.Control
                    type="email"
                    name={"customer_email"}
                    placeholder="example@customer.com"
                    ref={register({
                        required: "Please enter the email address",
                        validate: on_update_email
                    })}
                    isInvalid={errors["customer_email"]}
                    defaultValue={defaultEmail ? defaultEmail : ''}
                    disabled={loading}
                />
            </Col>
        </Form.Group>

        {(zohoContact && <Alert variant='info'>
            Updating existing contact.
        </Alert>)
            ||
            (loading && <Alert variant='light'>
                <Spinner animation="grow" role="status" size='sm' style={{ marginRight: 10 }}>
                </Spinner>
                Fetching contact details...
            </Alert>)
        }

        {Object.entries(fields).map(([key, field]) => (
            <Form.Group as={Row} key={'field_' + key}>
                <Form.Label column sm={labelWidth}>{field.type !== 'checkbox' && field.label}{field.required ? ' *' : ''}</Form.Label>
                <Col sm={inputWidth}>

                    {(field.type === 'select' &&
                        <Form.Control
                            as="select"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading || !zohoEmail}
                        >
                            <option value=""></option>
                            {getOptions(key, field).map(option =>
                                <option value={option.id} key={`f-${key}-${option.id}`}>{option.label}</option>
                            )
                            }
                        </Form.Control>)
                        ||
                        (field.type === 'checkbox' &&
                            <Form.Check
                                name={key}
                                ref={register({
                                    required: field.required
                                })}
                                isInvalid={errors[key]}
                                disabled={loading || !zohoEmail}
                                value={field.value}
                                label={field.label}
                            />)
                        ||
                        (field.type === 'textarea' &&
                            <Form.Control
                                as="textarea"
                                name={key}
                                ref={register({
                                    required: field.required
                                })}
                                row={3}
                                isInvalid={errors[key]}
                                disabled={loading || !zohoEmail}
                            />
                        )
                        ||
                        <Form.Control
                            type={field.type}
                            name={key}
                            placeholder=""
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading || !zohoEmail}
                        />
                    }
                </Col>
            </Form.Group>
        ))}


        <Button variant="primary" type="submit" disabled={loading || !zohoEmail}>
            {zohoContact ? "Update Contact" : "Create Contact"}
        </Button>

    </Form> : <div>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner> {submitting && "Saving contact..."}
    </div>
}
